import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/social_links"

const LenzenPage = () => (
  <Layout>
    <Seo title="Lenzen" />
    <section className="page-header">
      <h1>Lenzen</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content">
        <p>Optiek De Leersnijder biedt zowel standaardlenzen als maatwerklenzen: contactlenzen die alléén op jóuw oog zijn afgestemd qua sterkte, pasvorm en samenstelling. Daardoor ervaar jij kijk- en draagcomfort en voorkom je irritaties of serieuze gezondheidscomplicaties. Afhankelijk van jouw persoonlijke lifestyle, behoefte of wens bestaan er verschillende contactlenzen.</p>
        <p>Wij zijn gediplomeerde contactlens-specialisten hierdoor kunnen we u de meest comfortabele contactlenzen voorstellen den deze professioneel bij u aanpassen. U wenst toch ook de beste oogzorg?</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
        <StaticImage
            src="../images/lenzen.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="inline-block mx-2 w-12/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>
</Layout>
)

export default LenzenPage
